<template>
	<div class="mt-10 mb-4 px-4 hide-scrollbar" :style="`overflow-y:scroll; width: 100%; margin-top: ${marginTop}px`">
		<div>
			<h4 class="mb-3 mt-3">Notifications</h4>
		</div>
		<a-spin :spinning="loading">
			<a-card class="table-card h-full">
				<a-table class="tableStyle" :columns="columns" :data-source="notificationRecords"
					:row-key="(notification) => notification.id"
					:row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
					:scroll="{ x: 1350 }">
					<template #title>
						<div class="dF aC jE" style="gap: 50px">
							<a-button v-if="selectedRowKeys.length > 1" @click="bulkDelete" type="danger" ghost><a-icon
									type="delete" />BULK DELETE</a-button>
							<a-input-search v-model="search" placeholder="Search here" style="width: 300px"
								size="large" />
							<a-button @click="deleteAll" type="danger" ghost><a-icon type="delete" />DELETE
								ALL</a-button>
						</div>
					</template>
					<template #createdAt="createdAt">
						<span>{{ formatDate(createdAt) }}</span>
					</template>
					<div slot="actions" slot-scope="notification" style="justify-content: flex-end">
						<div class="pl-1 cursor-pointer dF aC jE mr-3" style="gap: 20px">
							<a-button v-if="notification.action" :type="notification.actionType || 'primary'"
								@click="performAction(notification, notification.action)" ghost>
								{{ notification.action }}
							</a-button>
							<a-button v-if="notification.action2" :type="notification.action2Type || 'primary'"
								@click="performAction(notification, notification.action2)" ghost>
								{{ notification.action2 }}
							</a-button>
							<a-icon @click="remove(notification)" type="delete" class="color-inherit"
								style="color: var(--danger); font-size: large;" />
						</div>
					</div>
				</a-table>
			</a-card>
		</a-spin>
	</div>
</template>

<script>
import { formatDate, checkStale, setCookie } from "bh-mod";
import moment from "moment";
import { getRoute } from 'bh-mod/components/layout/TopBar/Actions/notificationHelper'

export default {

	data() {
		return {
			loading: false,
			timer: null,
			search: '',
			selectedRowKeys: [],
			notifications: [],
			invitation: {
				id: '',
				show: false,
				instance: {},
				role: {},
				email: '',
				declineToken: '',
				notification: {}
			},
			instances: [],
			invitations: [],
			columns: [
				{
					title: "Notification",
					dataIndex: "message",
					key: "message",
					sorter: (a, b) =>
						a.message < b.message ? -1 : a.message > b.message ? 1 : 0,
				},
				{
					title: "Instance",
					dataIndex: "instance.name",
					key: "instance",
					sorter: (a, b) =>
						a.instance.name < b.instance.name ? -1 : a.instance.name > b.instance.name ? 1 : 0,
				},
				{
					title: "Created Date",
					dataIndex: "createdAt",
					key: "createdAt",
					scopedSlots: { customRender: "createdAt" },
					sorter: (a, b) =>
						moment(a.createdAt).format("x") -
						moment(b.createdAt).format("x"),
				},
				{
					title: "",
					key: "actions",
					scopedSlots: { customRender: "actions" },
				}
			],
			tld: window.location.href.includes('bildhive.com') ? 'com' : 'dev'
		}
	},

	computed: {
		notificationRecords() {
			return this.notifications.filter((n) =>
				n.message.toLowerCase().includes(this.search.toLowerCase())
			);
		},

		currentURL() {
			return window.location.href.substring(0, window.location.href.indexOf(this.tld) + 3);
		},

		marginTop() {
			return this.adminApp ? 75 : 0
		},

		adminApp() {
			return window.location.href.includes('https://admin.bildhive')
		}
	},

	created() {
		this.getNotifications();
	},

	methods: {
		formatDate,

		onSelectChange(selectedRowKeys) {
			this.selectedRowKeys = selectedRowKeys
		},

		bulkDelete() {
			if (!this.selectedRowKeys.length) {
				return this.$message.warn('Please select the notifications you want to delete.')
			}
			let self = this;
			this.$confirm({
				title: "Bulk Delete Notifications",
				content: (h) => (
					<div>Do you want to delete {self.selectedRowKeys.length} selected notifications?</div>
				),
				okText: "Delete",
				okType: "danger",
				cancelText: "Cancel",
				centered: true,
				onOk() {
					self.$api
						.post(`/notifications/bulk-delete`, { ids: self.selectedRowKeys })
						.then(({ data }) => {
							self.getNotifications();
							if (data.success) {
								self.unreadCount = 0;
								self.selectedRowKeys = [];
							}
						}).catch(err => {
							self.$message.error(self.$err(err))
						});
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		},

		deleteAll() {
			let self = this;
			this.$confirm({
				title: "Delete All Notifications",
				content: (h) => (
					<div>Do you want to delete all notifications?</div>
				),
				okText: "Delete",
				okType: "danger",
				cancelText: "Cancel",
				centered: true,
				onOk() {
					self.$api
						.get(`/notifications/clearall`)
						.then(({ data }) => {
							if (data.success) {
								self.notifications = [];
								self.unreadCount = 0;
							}
						}).catch(err => {
							self.$message.error(self.$err(err))
						});
				},
				onCancel() {
					console.log("Cancel");
				},
			});
		},

		remove(notification) {
			this.$api.delete("/notifications/" + notification.id);
			this.notifications = this.notifications.filter((x) => x.id != notification.id);
			if (notification.viewed == false) {
				this.unreadCount = this.unreadCount - 1;
			}
		},

		async getNotifications() {
			this.loading = true;
			let { data } = await this.$api.get("/notifications");

			this.notifications = data.filter((notification) => {
				return !notification.deleted;
			}).map((notification) => {
				return getRoute(notification, this.tld);
			});
			this.loading = false;
		},

		async performAction(notification, action = null) {
			if (notification.route) {
				if (notification.setInstance && notification.instance && notification.instance.id) {
					setCookie("instance", notification.instance.id);
				}
				if (notification.route.startsWith(this.currentURL) && notification.redirect && !window.location.href.includes('localhost')) {
					this.$router.push(notification.redirect);
				} else {
					// window.location.href = notification.route;
					window.open(notification.route, '_blank')
				}
			} else if (notification.group === 'instance:invite') {
				let invitation = this.invitations.find(i => i.instance.id === notification.data.instance)
				if (!invitation) {
					await this.getInvitations();
					invitation = this.invitations.find(i => i.instance.id === notification.data.instance);

					if (!invitation) {
						this.$message.error('No Invitation found for the instance. Please refresh the page and try again!')
						return this.getNotifications()
					}
				}
				if (action === 'Accept') {
					this.accept(invitation, notification)
				} else if (action === 'Decline') {
					this.decline(invitation, notification);
				}
			}
			this.$api
				.put("/notifications/" + notification.id)
				.then(({ data }) => {
					if (data.success) {
						let index = this.notifications.findIndex(
							(x) => x.id == notification.id
						);
						if (index != -1) {
							this.notifications[index].viewed = true;
						}
					}
				}).catch(err => {
					self.$message.error(self.$err(err))
				});
		},

		async accept({ instance, role, id, email }, notification) {
			this.invitation = { instance, role, id, email }
			this.invitation.accept = true
			this.invitation.notification = notification
			let self = this
			this.$confirm({
				title: 'Accepting Invitation',
				content: h => <div class='mt-4'>This will accept the invitation to <strong>{instance.name}</strong> with the role of {role.name == 'bh_admin' ? 'Admin' : role.name}. Are you sure?</div>,
				okText: 'OK',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				confirmLoading: self.loading,
				onOk() {
					self.confirmedAccept()
				}
			});
		},

		confirmedAccept() {
			this.$api.put('/user-invites/accept/' + this.invitation.id).then(() => {
				this.$message.success('Congrats! You now have access to ' + this.invitation.instance.name, 3);
				this.markActionPerformed(this.invitation.notification);
			}).catch(() => this.$message.error('Error occurred while accepting invitation. Please try again!'))
		},

		async decline({ instance, role, id, email, declineToken }, notification) {
			this.invitation = { instance, role, id, email, declineToken }
			this.invitation.accept = true
			this.invitation.notification = notification

			let self = this
			this.$confirm({
				title: 'Declining Invitation',
				content: h => <div class='mt-4'>This will decline the invitation to <strong>{instance.name}</strong> with the role of {role.name == 'bh_admin' ? 'Admin' : role.name}. Are you sure?</div>,
				okText: 'OK',
				okType: 'danger',
				cancelText: 'Cancel',
				centered: true,
				confirmLoading: self.loading,
				onOk() {
					self.confirmedDecline()
				}
			});
		},

		confirmedDecline() {
			this.$api.get('/user-invites/decline/' + this.invitation.declineToken).then(() => {
				this.$message.warn(`You have declined the invitation for ${this.invitation.instance.name}.`);
				this.markActionPerformed(this.invitation.notification);
			}).catch((error) => {
				console.error('Error while declining invitation', error)
				this.$message.error('Error occurred while declining invitation. Please try again!')
			})
		},

		markActionPerformed(notification) {
			this.$api
				.post(`notifications/${notification.id}/mark-action-performed`)
				.then(({ data }) => {
					this.requested = false;
					this.getNotifications()
					if (data.success) {
						let index = this.notifications.findIndex(
							(x) => x.id == notification.id
						);
						if (index != -1) {
							this.notification[index].viewed = true;
							this.notification[index].isActionPerformed = true;
						}
					}
					this.invitation = {
						id: '',
						show: false,
						instance: {},
						role: {},
						email: '',
						declineToken: '',
						notification: {}
					}
				}).catch((error) => {
					console.log('Error in notification action update', error)
				});
		},

		async getInvitations() {
			let { data } = await this.$api.get("/user-invites/me");
			this.invitations = data;
		},
	},

	mounted() {
		let self = this;
		document.addEventListener("visibilitychange", (e) => {
			if (document.visibilityState === "visible") {
				self.timer = setTimeout(() => {
					self.timer = null;
					let { isStale, onContinue } = checkStale();
					if (isStale && !self.isStaleAlready) {
						self.isStaleAlready = true;
						this.$confirm({
							title: "You are editing Multiple Projects at the same time",
							content: (h) => (
								<div>
									By Refreshing you will lose all unsaved
									data.
									<br /> By choosing to Continue, you will get
									to keep this project active.
								</div>
							),
							okText: "CONTINUE",
							cancelText: "REFRESH",
							onOk: () => {
								onContinue();
								self.isStaleAlready = false;
							},
							onCancel: () => {
								self.isStaleAlready = false;
								window.location.href = window.location.href;
							},
						});
					}
				}, 3000);
			} else {
				if (self.timer) {
					clearTimeout(self.timer);
				}
			}
		});
	},
}
</script>

<style lang="scss" scoped></style>
